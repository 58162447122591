import React from "react";
import { ABOUT_US_PATH,CONTACT_US_PATH,TERMS_CONDITION_PATH,PRIVACY_POLICY_PATH,SHIPPING_RETURN_POLICY_PATH } from "../../utils/Constants";
import ShippingandReturnPolicy from "../StaticContent/ShippingandReturnPolicy.json"
import PrivacyPolicy from "../StaticContent/PrivacyPolicy.json"
import Aboutus from "../StaticContent/Aboutus.json"
import Contactus from "../StaticContent/Contactus.json"
import TermsandConditions from "../StaticContent/TermsandConditions.json"
import { Footer } from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";
import "./Css/StaticComponent.css";



export default function StaticComponent() {
  let staticContent = ShippingandReturnPolicy
  const pageUrl = window.location.pathname
  console.log(pageUrl)
  let pageTitle = ""
  if (pageUrl.match(PRIVACY_POLICY_PATH)){
    staticContent = PrivacyPolicy;
    pageTitle = "Privacy and Policy";
  }
  else if (pageUrl.match(CONTACT_US_PATH)){
    staticContent = Contactus;
    pageTitle = "Contact us";
  }
  else if (pageUrl.match(ABOUT_US_PATH)){
    staticContent = Aboutus;
    pageTitle = "About us";
  }
  else if (pageUrl.match(SHIPPING_RETURN_POLICY_PATH)){
    staticContent = ShippingandReturnPolicy;
    pageTitle = "Shipping and Return Policy";
  }
  else if (pageUrl.match(TERMS_CONDITION_PATH)){
    staticContent = TermsandConditions;
    pageTitle = "Terms and Condition";
  }
  return (
    <div className="static-component">
      <Header/>
      <div className="body">
        {pageTitle && <h1>{pageTitle}</h1>}
      {staticContent.map((item) => (
        <section key={item.key} >
          {item.title && <h3 className="static-component-title">{item.title}</h3>}
          {item.description && <p
                className="static-component-para"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />}
         
        </section>
      ))}
      </div>
      <Footer/>
    </div>
  );
}
