import "./SelectBox.css";

const DEFAULT_LABEL_OPTION = "DEFAULT_LABEL_OPTION";
export default function SelectBox(props) {
  const SELECT_STYLE = {
    paddingLeft: props.padding,
    paddingRight: props.padding,
  };

  return (
    <div
      className="selectbox-base"
      style={{
        "--sidebar-padding": props.padding.toString().includes("px")
          ? props.padding
          : props.padding + "px",
      }}
    >
      <select
        value={props.value ? props.value : DEFAULT_LABEL_OPTION}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
        style={SELECT_STYLE}
      >
        <option value={DEFAULT_LABEL_OPTION} disabled>
          {props.label}
        </option>
        {props.data.map((i, index) => (
          <option value={i} key={index}>
            {i}
          </option>
        ))}
      </select>
    </div>
  );
}

SelectBox.defaultProps = {
  defaultValue: DEFAULT_LABEL_OPTION,
  data: [],
  padding: 10,
};
