import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { GOOGLE_MAP_KEY } from '../../../utils/Constants';
import { primaryColorLight } from '../../../utils/Theme';
import './Css/LocationSearch.css';

export default function LocationSearch(props) {
  const [loaded, setLoaded] = useState(false);
  const [address, setAddress] = useState(props.location.text);
  const [countryCode, updateCountryCode] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null
  });

  useEffect(() => {
    if (!props.location.text) return;
    if (props.location.text === address) return;

    setAddress(props.location.text);
  }, [props.location.text]);

  useEffect(() => {
    // Loading Google Map Script
    try {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.type = 'text/javascript';
      script.onload = async () => {
        setLoaded(true);
      };
      document.body.appendChild(script);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (!coordinates.lat && !coordinates.lng) return;
    props.updateLocation({ text: address, ...coordinates, countryCode });
    // eslint-disable-next-line
  }, [coordinates]);
  const getCountryCode = (addresses) => {
    return addresses.find((add) => add.types.includes('country'))?.short_name;
  };
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    if (!results[0]) {
      return false;
    }
    const country = getCountryCode(results[0].address_components);
    if (country) {
      updateCountryCode(country);
    }
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  if (!loaded) return null;

  return (
    <div>
      <PlacesAutocomplete
        debounce={300}
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {/* <p>Latitude: {coordinates.lat}</p>
            <p>Longitude: {coordinates.lng}</p> */}

            <input
              className='createmap-input'
              {...getInputProps({
                placeholder: 'XYZ Street, Delhi'
              })}
            />

            <ul className='locationsearch-resultBase'>
              {loading ? (
                <li className='locationsearch-listItem'>Loading...</li>
              ) : null}

              {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? '#efefef' : '#fefefe',
                  width: '100%',
                  cursor: 'pointer'
                };

                return (
                  <li
                    key={index}
                    className='locationsearch-listItem'
                    {...getSuggestionItemProps(suggestion, { style })}
                  >
                    {suggestion.description}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}

LocationSearch.defaultProps = {
  location: { text: '' }
};
