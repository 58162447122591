import React, { useContext } from 'react';
import './Css/CreateMap.css';
import LocationSearch from '../LocationSearch/LocationSearch';
import Button from '../../../general/Button';
import {
  CREATE_MAP_TAB_MODES,
  DATE_OBJ,
  range,
  TIME_OBJ,
  OFFSET_CHART
} from '../../../utils/Constants';
import { hoverBackgroundColor, primaryColor } from '../../../utils/Theme';
import { UserJourneyContext } from '../ContextProvider/ContextProvider';
import SelectBox from '../../../general/SelectBox';

export default function CreateMapMoment(props) {
  const input = useContext(UserJourneyContext).designInput;
  const setInput = useContext(UserJourneyContext).setDesignInput;
  const offsetHours = useContext(UserJourneyContext).offsetHours;
  const setOffsetHours = useContext(UserJourneyContext).setOffsetHours;

  //get the last day, so the number of days in that month
  const DAYS_LENGTH = new Date(
    new Date(input.time).getFullYear(),
    new Date(input.time).getMonth() + 1,
    0
  ).getDate();
  const DAYS_ARR = range(1, DAYS_LENGTH, 1);

  return (
    <div className='createmap-moment-base'>
      <div className='createmap-inputContainer'>
        <label className='createmap-label'>Location</label>
        <LocationSearch
          location={{
            text: input.text.location.toUpperCase(),
            lat: input.lat,
            lng: input.lon
          }}
          updateLocation={(value) => {
            console.log(value);
            setInput({
              ...input,
              lat: parseFloat(value.lat.toFixed(4)),
              lon: parseFloat(value.lng.toFixed(4)),
              text: {
                ...input.text,
                location: value.text.toUpperCase(),
                coords: {
                  lat: parseFloat(value.lat.toFixed(4)),
                  lon: parseFloat(value.lng.toFixed(4))
                }
              }
            });
            if (value.countryCode && OFFSET_CHART[value.countryCode]) {
              setOffsetHours(OFFSET_CHART[value.countryCode]);
            }
          }}
        />
      </div>

      <div className='createmap-inputContainer'>
        <label className='createmap-label'>
          When was your special occasion?
        </label>

        <div
          className='createmap-inputContainer-splite'
          style={{ marginBottom: 15 }}
        >
          <SelectBox
            label={'Year'}
            value={new Date(input.time).getFullYear().toString()}
            data={DATE_OBJ.years}
            onChange={(value) => {
              const date = new Date().setFullYear(value);
              setInput({
                ...input,
                time: date,
                text: {
                  ...input.text,
                  time: new Date(date)
                    .toLocaleString('en-in', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric'
                    }).replace(',', '').toUpperCase()
                }
              });
            }}
          />

          <SelectBox
            label={'Month'}
            value={DATE_OBJ.months[new Date(input.time).getMonth()]}
            data={DATE_OBJ.months}
            onChange={(value) => {
              const monthIndex = DATE_OBJ.months.findIndex((i) => i === value);
              let date = new Date(input.time).setMonth(monthIndex);
              date = new Date(date).setDate(1);
              setInput({
                ...input,
                time: date,
                text: {
                  ...input.text,
                  time: new Date(date).toLocaleString('en-in', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  }).replace(',', '').toUpperCase()
                }
              });
            }}
          />

          <SelectBox
            label={'Day'}
            value={new Date(input.time).getDate().toString()}
            data={DAYS_ARR}
            onChange={(value) => {
              const date = new Date(input.time).setDate(value);
              setInput({
                ...input,
                time: date,
                text: {
                  ...input.text,
                  time: new Date(date).toLocaleString('en-in', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  }).replace(',', '').toUpperCase()
                }
              });
            }}
          />
        </div>

        <div
          className='createmap-inputContainer-splite'
          style={{ marginBottom: 15 }}
        >
          <SelectBox
            label={'Hours'}
            value={
              input.time && new Date(input.time).getHours() === 0
                ? 12
                : new Date(input.time).getHours() > 12
                ? new Date(input.time).getHours() - 12
                : new Date(input.time).getHours()
            }
            data={TIME_OBJ.hours}
            onChange={(value) => {
              const date = new Date(input.time).setHours(value);
              setInput({
                ...input,
                time: date,
                text: {
                  ...input.text,
                  time: new Date(date).toLocaleString('en-in', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  }).replace(',', '').toUpperCase()
                }
              });
            }}
          />

          <span>
            <h3>:</h3>
          </span>

          <SelectBox
            label={'Minutes'}
            value={input.time ? new Date(input.time).getMinutes() : ''}
            data={TIME_OBJ.minutes}
            onChange={(value) => {
              const date = new Date(input.time).setMinutes(value);
              setInput({
                ...input,
                time: date,
                text: {
                  ...input.text,
                  time: new Date(date).toLocaleString('en-in', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  }).replace(',', '').toUpperCase()
                }
              });
            }}
          />

          <SelectBox
            label={'Meridium'}
            value={
              input.time && new Date(input.time).getHours() < 12 ? 'AM' : 'PM'
            }
            data={TIME_OBJ.meridium}
            onChange={(value) => {
              let hours = new Date(input.time).getHours();
              if (value == 'PM') hours += 12;
              if (hours >= 12 && value == 'AM') hours -= 12;

              const date = new Date(input.time).setHours(hours);
              setInput({
                ...input,
                time: date,
                text: {
                  ...input.text,
                  time: new Date(date).toLocaleString('en-in', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  }).replace(',', '').toUpperCase()
                }
              });
            }}
          />
        </div>
        <label className='createmap-label'>
          Timezone
        </label>
        <SelectBox
          label={'Select Offset'}
          data={TIME_OBJ.offset}
          value={offsetHours}
          onChange={(value) => setOffsetHours(value)}
        />
      </div>
       
      <div className='createmap-btnContainer'>
        <Button
          label='Next'
          width={'100%'}
          backgroundColor={primaryColor}
          hoverBackgroundColor={hoverBackgroundColor}
          textStyle={{ fontSize: 16, fontWeight: 600 }}
          borderRadius={7}
          onClick={() => props.tabChange(CREATE_MAP_TAB_MODES.design)}
        />
      </div>
    </div>
  );
}
