import { useState } from "react";
import "./Accordion.css";

export default function Accordion(props) {
  const [active, setActive] = useState(false);
  return (
    <div className="accordion-base">
      <button
        className={active ? "accordion active" : "accordion"}
        onClick={() => setActive(!active)}
      >
        {props.title}
      </button>
      <div className={active ? "panel active" : "panel"}>{props.content}</div>
    </div>
  );
}

Accordion.defaultProps = {
  title: "Heading",
  content: (
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas quisquam
      possimus laudantium, architecto nihil impedit et? Quis adipisci dolor
      illum aliquid voluptates similique eaque alias voluptate, sed ipsam
      distinctio. Provident.
    </p>
  ),
};
