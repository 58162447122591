import "./Button.css";
import PropTypes from "prop-types";
import { useState } from "react";

export default function Button(props) {
  const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);

  let className = "button-base";
  if (props.hoverParent.hovered && !props.loading)
    className = "button-parentHovered";
  if (props.disabled) className = "button-disabled";
  if (props.loading) className = "button-loading";

  function handleClick(e) {
    if (props.onClick) {
      props.onClick(e);
    }
  }

  return (
    <div
      className={className}
      style={{
        height: props.height,
        width: props.width,
        borderRadius: props.borderRadius,
        backgroundColor:
          className === "button-parentHovered"
            ? props.hoverParent.backgroundColor
            : backgroundColor,
        backgroundImage:
          props.backgroundImage && `url(${props.backgroundImage})`,
        border: props.border,
        transition: props.transition,
      }}
      onMouseEnter={() =>
        props.hoverBackgroundColor &&
        setBackgroundColor(props.hoverBackgroundColor)
      }
      onMouseLeave={() => setBackgroundColor(props.backgroundColor)}
      onClick={(e) => handleClick(e)}
    >
      <span
        style={{
          ...props.textStyle,
          color:
            className === "button-parentHovered"
              ? props.hoverParent.color
              : props.textStyle.color,
        }}
      >
        {props.label}
      </span>
    </div>
  );
}

Button.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  backgroundColor: PropTypes.string,
  label: PropTypes.any,
  hoverBackgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  border: PropTypes.string,
  borderRadius: PropTypes.number,
  textStyle: PropTypes.shape({
    color: PropTypes.string,
    fontSize: PropTypes.number,
    fontWeight: PropTypes.number,
    fontFamily: PropTypes.string,
  }),
  icon: PropTypes.shape({
    element: PropTypes.element,
    hoveredElement: PropTypes.element,
    size: PropTypes.number,
    offset: PropTypes.number,
  }),
};

Button.defaultProps = {
  height: 40,
  backgroundColor: "#000",
  disabled: false,
  loading: false,
  hoverParent: { hovered: false, backgroundColor: "#000", color: "#fff" },
  textStyle: {
    color: "#fff",
    fontSize: 14,
    fontWeight: 400,
  },
};
