import { useState } from "react";
import UserJourney from "./Components/UserJourney/UserJourney.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home.js";

import StaticComponent from "./Components/StaticContent/StaticComponent.js";
import { ABOUT_US_PATH, CONTACT_US_PATH,  PRIVACY_POLICY_PATH, SHIPPING_RETURN_POLICY_PATH, TERMS_CONDITION_PATH } from "./utils/Constants.js";

function App() {
  const [ShowModal, setShowModal] = useState(false);
  const ModalToggle = () => setShowModal(!ShowModal);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/create-order"
          element={
            <UserJourney ShowModal={ShowModal} ModalToggle={ModalToggle} />
          }
        />
        <Route
          path= {CONTACT_US_PATH}
          element={
            <StaticComponent/>
          }
        />
        <Route
          path= {TERMS_CONDITION_PATH}
          element={
            <StaticComponent/>
          }
        />
        <Route
          path= {SHIPPING_RETURN_POLICY_PATH}
          element={
            <StaticComponent/>
          }
        />
        <Route
          path= {ABOUT_US_PATH}
          element={
            <StaticComponent/>
          }
        />
        <Route
          path= {PRIVACY_POLICY_PATH}
          element={
            <StaticComponent/>
          }
        />
      </Routes>
    </BrowserRouter>
   
  );
}

export default App;
