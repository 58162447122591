import { createContext, useEffect, useState } from 'react';
import { get } from '../../../utils/apiRequest';
import {
  CREATE_MAP_DEFAULT_ASPECT_RATIO,
  CREATE_MAP_DEFAULT_CONSTELLATIONS,
  CREATE_MAP_DEFAULT_NO_OF_STARS,
  CREATE_MAP_DEFAULT_STAR_COLOR,
  CREATE_MAP_DEFAULT_SUBHEAD,
  CREATE_MAP_DEFAULT_THEME,
  INDIA,
  OFFSET_CHART,
  CREATE_MAP_DEFAULT_HEADING_1
} from '../../../utils/Constants';

export const UserJourneyContext = createContext();

export default function ContextProvider(props) {
  const [designInput, setDesignInput] = useState({
    time: new Date(),
    lat: null,
    lon: null,
    bg_style: CREATE_MAP_DEFAULT_THEME,
    aspect_ratio: CREATE_MAP_DEFAULT_ASPECT_RATIO.ratio,
    // num_stars:3000,
    res_scaler: CREATE_MAP_DEFAULT_ASPECT_RATIO.scale,
    star_color: CREATE_MAP_DEFAULT_STAR_COLOR,
    constellations: CREATE_MAP_DEFAULT_CONSTELLATIONS,
    compass: false,
    text: {
      // heading: "",
      // heading_2: "",
      // sub_heading: "",
      location: '',
      time: new Date()
        .toLocaleString('en-in', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        })
        .replace(',', '')
        .toUpperCase(),
      coords: { lat: '', lng: '' }
    }
  });
  const [designDebounceInput, setDesignDebounceInput] = useState({
    heading1: CREATE_MAP_DEFAULT_HEADING_1,
    heading2: '',
    subHeading: CREATE_MAP_DEFAULT_SUBHEAD,
    num_stars: CREATE_MAP_DEFAULT_NO_OF_STARS,
    time: ''
  });

  const [purchaseDetails, setPurchaseDetails] = useState({
    subTotal: 0,
    totalCost: 0,
    discount: 0,
    currency: '',
    orderType: '',
    size: '',
    discountCode: ''
  });

  const [selectedCountry, setSelectedCountry] = useState('');
  const [offsetHours, setOffsetHours] = useState('');
  const [costDetails, setCostDetails] = useState([]);

  // DELIVERY DETAILS
  const [deliveryDetails, setDeliveryDetails] = useState({
    email: '',
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    state: '',
    zipCode: '',
    phoneNumber: ''
  });

  const [razorPayDetails, setRazorPayDetails] = useState({
    // id: "order_IWrAdtSKELKIUL",
    // entity: "order",
    // amount: 250,
    // amount_paid: 0,
    // amount_due: 250,
    // currency: "INR",
    // receipt: "receipt_blah1@gmail.com",
    // offer_id: null,
    // status: "created",
    // attempts: 0,
    // notes: [],
    // created_at: 1639376091,
  });

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    // Getting Location

    if (!selectedCountry) {
      try {
        const response = await get('location');
        const responseJson = await response.json();
        setDesignInput({
          ...designInput,
          lat: responseJson.data.ll[0],
          lon: responseJson.data.ll[1],
          text: {
            ...designInput.text,
            location: responseJson.data.city.toUpperCase(),
            coords: {
              lat: responseJson.data.ll[0],
              lon: responseJson.data.ll[1]
            }
          }
        });
        let country = INDIA;
        if (responseJson && responseJson.data && responseJson.data.country) {
          country = responseJson.data.country;
        }
        setSelectedCountry(country);
        setDeliveryDetails({
          ...deliveryDetails,
          country: country
        });
        setOffsetHours(OFFSET_CHART[country]);
        getCostDetails(country);        
      } catch (err) {
        console.log(err);
      }
    } else {
      getCostDetails();
    }
  };

  const getCostDetails = async (countrySelected = selectedCountry) => {
    if (getCostDetails.length) return;
    try {
      const response = await get('payment');
      const responseJson = await response.json();

      // const responseJson = {
      //   status: "success",
      //   message: "Success",
      //   data: [
      //     {
      //       type: "soft_copy",
      //       price: {
      //         inr: 3500,
      //         usd: 35,
      //       },
      //     },
      //     {
      //       type: "hard_copy",
      //       size: "13x13cm",
      //       price: {
      //         inr: 5000,
      //         usd: 50,
      //       },
      //     },

      //     {
      //       type: "hard_copy",
      //       size: "16x16cm",
      //       price: {
      //         inr: 8000,
      //         usd: 80,
      //       },
      //     },
      //   ],
      // };
      if (responseJson.status === 'success') {
        setCostDetails(responseJson.data);

        if (countrySelected === INDIA) {
          const selectedOrderType = responseJson.data.filter(
            (i) => i.type === 'hard_copy'
          )[1];
          const price = selectedOrderType.price.inr;
          setPurchaseDetails({
            subTotal: price,
            totalCost: price,
            discount: 0,
            size: selectedOrderType.frameSize,
            currency: 'INR',
            orderType: 'hard_copy'
          });
        } else {
          const price = responseJson.data.find((i) => i.type === 'soft_copy')
            .price.usd;
          setPurchaseDetails({
            subTotal: price,
            totalCost: price,
            discount: 0,
            currency: 'USD',
            orderType: 'soft_copy'
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resetAll = () => {
    setDesignInput({
      time: new Date(),
      lat: null,
      lon: null,
      bg_style: CREATE_MAP_DEFAULT_THEME,
      aspect_ratio: CREATE_MAP_DEFAULT_ASPECT_RATIO.ratio,
      // num_stars:3000,
      res_scaler: CREATE_MAP_DEFAULT_ASPECT_RATIO.scale,
      star_color: CREATE_MAP_DEFAULT_STAR_COLOR,
      constellations: CREATE_MAP_DEFAULT_CONSTELLATIONS,
      compass: false,
      text: {
        location: '',
        time: new Date().toLocaleString('en-in', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }),
        coords: { lat: '', lng: '' }
      }
    });

    setDesignDebounceInput({
      heading1: CREATE_MAP_DEFAULT_HEADING_1,
      heading2: '',
      subHeading: CREATE_MAP_DEFAULT_SUBHEAD,
      num_stars: CREATE_MAP_DEFAULT_NO_OF_STARS,
      time: ''
    });

    if (selectedCountry === INDIA) {
      const selectedOrderType = costDetails.filter(
        (i) => i.type === 'hard_copy'
      )[1];
      const price = selectedOrderType.price.inr;

      setPurchaseDetails({
        subTotal: price,
        totalCost: price,
        discount: 0,
        size: selectedOrderType.frameSize,
        currency: 'INR',
        orderType: 'hard_copy',
        discountCode: ''
      });
    } else {
      const price = costDetails.find((i) => i.type === 'soft_copy').price.usd;
      setPurchaseDetails({
        subTotal: price,
        totalCost: price,
        discount: 0,
        currency: 'USD',
        orderType: 'soft_copy',
        size: '',
        discountCode: ''
      });
    }

    setDeliveryDetails({
      email: '',
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      state: '',
      zipCode: '',
      phoneNumber: ''
    });
  };

  return (
    <UserJourneyContext.Provider
      value={{
        designInput,
        setDesignInput,
        designDebounceInput,
        setDesignDebounceInput,
        offsetHours,
        setOffsetHours,
        purchaseDetails,
        setPurchaseDetails,
        selectedCountry,
        costDetails,
        deliveryDetails,
        setDeliveryDetails,
        razorPayDetails,
        setRazorPayDetails,
        resetAll,
      }}
    >
      {props.children}
    </UserJourneyContext.Provider>
  );
}
