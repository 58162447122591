import ContextProvider from "./ContextProvider/ContextProvider";
import CreateMap from "./CreateMap/CreateMap";

export default function UserJourney(props) {
  return (
    <ContextProvider>
      <CreateMap ShowModal={props.ShowModal} ModalToggle={props.ModalToggle} />
    </ContextProvider>
  );
}
