import React from 'react';
import {
  ABOUT_US_PATH,
  CONTACT_US_PATH,
  PRIVACY_POLICY_PATH,
  SHIPPING_RETURN_POLICY_PATH,
  TERMS_CONDITION_PATH
} from '../../../utils/Constants';
import './Css/Footer.css';
import { PUBLIC_IMAGE_PATH } from '../../../utils/Constants';

export const Footer = () => {
  return (
    <footer className='footer-base'>
      <div className='left-footer-listItem'>
        <div className='footer-listItem'>
          <img src={PUBLIC_IMAGE_PATH + 'main_logo.png'} alt='logo' />
        </div>
        <div className='footer-listItem'>
          <p>
            Copyright &copy; 2022<br></br> Cosmicframe.All rights<br></br>{' '}
            reserved.
          </p>
        </div>
      </div>
      <div className='center-footer-listItem'>
        <div className='footer-listItem'>
          <a href={ABOUT_US_PATH}>About Us</a>
        </div>

        <div className='footer-listItem'>
          <a href={SHIPPING_RETURN_POLICY_PATH}>Shipping and return Policy</a>
        </div>
        <div className='footer-listItem'>
          <a href={PRIVACY_POLICY_PATH}>Privacy Policy</a>
        </div>

        <div className='footer-listItem'>
          <a href={CONTACT_US_PATH}>Contact Us</a>
        </div>
        <div className='footer-listItem'>
          <a href={TERMS_CONDITION_PATH}>Terms and Conditions</a>
        </div>
      </div>
      <div className='right-footer-listItem'>
        <div className='footer-listItem'>
          Follow us on :
          <div className='footer-listItem'>
            <a href='https://www.instagram.com/cosmic.frame/' target='_blank'>
              <img src={PUBLIC_IMAGE_PATH + 'instagram_logo.png'} alt='logo' />
            </a>
            <a href='https://www.facebook.com/cosmicframe.store/' target='_blank' >
              <img src={PUBLIC_IMAGE_PATH + 'facebook_logo.png'} alt='logo'  />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
