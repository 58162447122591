import React, { useState, useEffect } from "react";
import "./Checkbox.css";

export default function Checkbox(props) {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const toggle = (value) => {
    setChecked(value);
    if (props.changeSelected) props.changeSelected(value);
  };

  return (
    <label
      className="switch"
      style={{
        fontSize: props.height,
        height: props.height,
        width: 2 * props.height,
        borderRadius: props.height,
        backgroundColor: checked ? props.backgroundColor : "#ccc",
        cursor: props.disabled && "not-allowed",
      }}
    >
      <input
        type="checkbox"
        disabled={props.disabled}
        checked={checked}
        onChange={(e) => !props.disabled && toggle(e.target.checked)}
        disabled={props.disabled}
        style={{
          cursor: props.disabled && "not-allowed",
        }}
      />
      <div
        className="toggleSwitch"
        style={{
          height: props.height,
          width: props.height,
          borderRadius: props.height,
          backgroundColor: props.toggleColor,
        }}
      ></div>
    </label>
  );
}

Checkbox.defaultProps = {
  height: 20,
  backgroundColor: "#ccc",
  toggleColor: "#fff",
  checked: false,
  disabled: false,
};
