import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import "./Strip.css";
import { post, API_URL, patch } from "../utils/apiRequest";

const publishableKey =
  "pk_test_51JtC0oSDOrAyagwQ9qLdq57gea6yOLpIl1eYaumsWgeDSBYcqCGhe9kyikmrjawf2RHGf4Z8NTRInRMYqxtOj1Rf00o6aTtP5I";
const secretKey =
  "sk_test_51JtC0oSDOrAyagwQX4mHL4cLe7Cn1Mspok0fXDjj8HLhMXTB2RidasWg265SG5IZU9C89fTP1OGBbOrrgRRXUUsQ00z4YOQnQi";

const stripePromise = loadStripe(publishableKey);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "http://localhost:3001/complete",
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit} className="stripBase">
      <PaymentElement />
      <button disabled={!stripe} className="submitBtn">
        Submit
      </button>
    </form>
  );
};

export default function Stripe() {
  const [stripPaymentIntent, setStripPaymentIntent] = useState("");
  useEffect(() => {
    getStripPaymentIntent();
  }, []);
  const getStripPaymentIntent = async () => {
    const req = await post(
      `stripe/getPaymentIntent`,
      {
        amount: 100,
      },
      API_URL
    );
    const resJson = await req.json();
    console.log(resJson);
  };
  const paymentIntentId =
    "pi_3K6sniSDOrAyagwQ169xTgOs_secret_gWbvEyx6G28eTtfdML5rgMDLm";
  const options = {
    // passing the client secret obtained from the server
    // clientSecret: "{{CLIENT_SECRET}}",
    clientSecret: paymentIntentId,
  };

  if (!stripePromise) return null;
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
}
