import { useEffect, useState } from "react";
import { hoverBackgroundColor, primaryColor } from "../utils/Theme";
import Button from "./Button";

const key_id = "rzp_test_Z2mSNqYeGmCjVR";

export default function RazorPay(props) {
  const [loaded, setLoaded] = useState(false);

  const options = {
    key: key_id,
    name: "Cosmicframe",
    order_id: props.order_id,
    // amount: props.amount.toString(), //  = INR 1
    // amount: "25000",
    currency: props.currency,
    receipt: props.receipt,
    description: "Test Transaction",
    image: props.logo,
    handler: async function (response) {
      // alert(response.razorpay_payment_id);

      props.onSuccess && (await props.onSuccess(response));
    },
    prefill: {
      name: props.name,
      contact: props.phoneNumber,
      email: props.email,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "black",
      hide_topbar: false,
    },
  };

  const openPayModal = () => {
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  useEffect(() => {
    // Loading Razor Pay Script
    try {
      const script = document.createElement("script");
      script.src = `https://checkout.razorpay.com/v1/checkout.js`;
      script.async = true;
      script.defer = true;
      script.type = "text/javascript";
      script.onload = async () => {
        setLoaded(true);
        openPayModal();
      };
      document.body.appendChild(script);
    } catch (err) {
      console.error(err);
    }
  }, []);
  if (!loaded) return null;

  const handleClick = async () => {
    if (props.onClick) {
      await props.onClick();
      // After onClick opening RazorPay Modal
      openPayModal();
    }
  };

  // return <button onClick={openPayModal}>Pay with Razorpay</button>;
  return (
    // <Button
    //   label="Continue To Payment"
    //   width={200}
    //   backgroundColor={primaryColor}
    //   hoverBackgroundColor={hoverBackgroundColor}
    //   textStyle={{ fontSize: 16, fontWeight: 600 }}
    //   borderRadius={7}
    //   onClick={handleClick}
    // />
    <div />
  );
}

RazorPay.defaultProps = {
  order_id: "",
  // amount: 50,
  currency: "INR",
  receipt: "receipt_sample",
  name: "John Doe",
  phoneNumber: "9999999999",
  email: "demo@demo.com",
};
