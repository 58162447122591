import React from "react";
import "./RadarLoader.css";

export default function RadarLoader(props) {
  return (
    <div
      className="loader-radar"
      style={{
        height: props.size,
        width: props.size,
        "--scanWidth": `${props.size / 1.5}px`,
      }}
      // style={someStyle}
    >
      <div
        className="loader-radar-dot"
        style={{
          width: props.dotSize,
          height: props.dotSize,
        }}
      />
    </div>
  );
}

RadarLoader.defaultProps = {
  size: 64,
  dotSize: 2,
};
