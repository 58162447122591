import React from "react";
import PropTypes from "prop-types";

import "./Image.css";
import VisibilityChild from "./VisibilityChild";

const LOADING = "loading";
const LOADED = "loaded";
const ERROR = "error";

const colors = ["#f4fff9", "#ffedf3", "#fff2df", "#e5f1ff", "#f0f7f1"];
export default class Image extends React.Component {
  state = {
    status: LOADING,
    colorIndex: Math.floor(Math.random() * 5),
  };
  // shouldComponentUpdate(nextProps, nextState) {
  //   // if (this.state.status !== nextState.status) {
  //   //   return true;
  //   // }
  //   if (this.props.src === nextProps.src) {
  //     return false;
  //   }
  // }
  //image onLoad handler to update state to loaded
  onLoad = () => {
    this.setState({ status: LOADED });
  };
  onError = () => {
    this.setState({ status: ERROR });
  };
  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.setState({ status: LOADING });
    }
  }
  style = (loading) => {
    return {
      transition: "opacity .5s linear",
      opacity: `${loading === LOADING ? 0 : 1}`,
    };
  };
  render() {
    let { className, loadedClassName, loadingClassName, ...props } = this.props;

    className = `${className} ${
      this.state.status === LOADED ? loadedClassName : loadingClassName
    }`;

    return (
      <VisibilityChild
        minHeight={this.props.paddingBottom}
        backgroundColor={colors[this.state.colorIndex]}
      >
        <div
          className={"imgContainer"}
          style={{
            paddingBottom: this.props.paddingBottom,
            background:
              this.props.showBackgroundColor && this.state.status !== LOADED
                ? colors[this.state.colorIndex]
                : "none",
          }}
        >
          {this.state.status === ERROR ? (
            <div />
          ) : (
            <img
              src={this.props.src}
              style={this.style(this.state.status)}
              onClick={this.props.onClick}
              className={className}
              onLoad={this.onLoad}
              onError={this.onError}
              alt={this.props.alt ? this.props.alt : ""}
            />
          )}
        </div>
        {/* <div
          className={
            this.props.verticallyCenter
              ? styles.verticallyCenter
              : styles.fullWidth
          }
        >
          {this.state.imageStatus !== ERROR && this.props.src ? (
            <img
              className={
                this.props.verticallyCenter
                  ? styles.verticallyCenterBase
                  : styles.base
              }
              alt="No Image"
              src={this.props.src}
              onLoad={() => this.setState({ imageStatus: LOADED })}
              onError={() => this.setState({ imageStatus: ERROR })}
              style={{ height: this.props.height }}
            />
          ) : (
            <div />
          )}
        </div> */}
      </VisibilityChild>
    );
  }
}
Image.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.string,
  verticallyCenter: PropTypes.bool,
  paddingBottom: PropTypes.string,
  showBackgroundColor: PropTypes.bool,
};
Image.defaultProps = {
  height: "auto",
  verticallyCenter: false,
  className: "",
  loadingClassName: "img-loading",
  loadedClassName: "img-loaded",
  paddingBottom: "100%",
  showBackgroundColor: true,
};
