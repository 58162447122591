import { getCookie } from "./Cookies";
import { ACCESS_TOKEN } from "./Constants";

export const IMAGE_API_URL = process.env.REACT_APP_IMAGE_API_KEY;

// export const LOCAL_API_URL = "http://localhost:5000";
export const API_URL = process.env.REACT_APP_API_KEY;

export const get = (url, apiUrl = API_URL) => {
  const headers = {
    "Content-Type": "application/json"
  };

  const accessToken = getCookie(ACCESS_TOKEN);
  if (accessToken) {
    headers.Authorization = "Bearer " + accessToken;
  }

  try {
    return fetch(`${apiUrl}/${url}`, {
      method: "get",
      headers
    });
  } catch (err) {
    return err.Error;
  }
};

export const post = (url, data = [], apiURL = API_URL) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache"
    };

    const accessToken = getCookie(ACCESS_TOKEN);
    // const accessToken =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhYTY5YTJmYS1kZWIxLTQzMmEtOWUwZS1mYzU0M2UzMWQxYzgiLCJyb2xlIjoiY3VzdG9tZXIiLCJpYXQiOjE2Mzk1NDMxODAsImV4cCI6MTY0MDQwNzE4MH0.y9VFo3tx8RJZNS0hT9h2U2IAH7iCLxU36U-6V9iNnZQ";
    if (accessToken) {
      headers.Authorization = "Bearer " + accessToken;
    }

    return fetch(`${apiURL}/${url}`, {
      method: "post",
      body: JSON.stringify(data),
      headers
    });
  } catch (err) {
    console.log(err);
  }
};

export const patch = (url, data = {}, apiURL = API_URL) => {
  const headers = {
    "Content-Type": "application/json",
    mode: " no-cors"
    // "Cache-Control": "no-cache",
  };
  const accessToken = getCookie(ACCESS_TOKEN);
  if (accessToken) {
    headers.Authorization = "Bearer " + accessToken;
  }
  return fetch(`${apiURL}/${url}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers
  });
};
