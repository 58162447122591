import { useState } from "react";
import "./SliderWithToolTip.css";

export default function SliderWithToolTip(props) {
  const [value, setValue] = useState(props.value);

  const active = props.activeColor;
  const updateValue = (value) => {
    setValue(value);
    props.onChange(value);
  };

  // const progress = (value / props.max) * 100 + "%";
  const progress = ((value - props.min) * 100) / (props.max - props.min) + "%";

  const styleInput = {
    background: `linear-gradient(90deg, ${active} 0% ${progress},   ${props.inActiveColor} ${progress} 100%)`,
    height: props.height,
    borderRadius: props.borderRadius,
  };

  return (
    <div className="sliderWithToolTip-base">
      {/* {props.min} */}
      <input
        className="slider"
        type="range"
        min={props.min}
        max={props.max}
        value={value}
        onChange={(e) => updateValue(e.target.value)}
        style={styleInput}
      />
      {/* {props.max} */}
      {props.showToolTip && (
        <span
          className="slider-toolTip"
          style={{
            left: progress,
            color: props.activeColor,
          }}
        >
          {value}
        </span>
      )}
    </div>
  );
}

SliderWithToolTip.defaultProps = {
  showToolTip: false,
  value: 50,
  min: 1,
  max: 100,
  activeColor: "#d3d3d3",
  inActiveColor: "#eee",
  height: 10,
  borderRadius: 5,
};
