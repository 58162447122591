import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  CREATE_MAP_DEFAULT_ASPECT_RATIO,
  CREATE_MAP_TAB_MODES,
  getCoordinatesText,
  getGmtTime,
  PUBLIC_IMAGE_PATH,
  HOME_PATH,
} from '../../../utils/Constants';
import { primaryColor } from '../../../utils/Theme';
import { IMAGE_API_URL, post } from '../../../utils/apiRequest';
import RadarLoader from '../../../general/RadarLoader';
import { useDebouncedValue } from '../../../utils/useDebounce';
import { UserJourneyContext } from '../ContextProvider/ContextProvider';
import CreateMapMoment from './CreateMapMoment';
import CreateMapDesign from './CreateMapDesign';
import CreateMapFinalise from './CreateMapFinalise';
import './Css/CreateMap.css';
import { Link } from 'react-router-dom';

export default function CreateMap(props) {
  const input = useContext(UserJourneyContext).designInput;
  const designDebounceInput =
    useContext(UserJourneyContext).designDebounceInput;
  const offsetHours = useContext(UserJourneyContext).offsetHours;

  const [mode, setMode] = useState(CREATE_MAP_TAB_MODES.moment);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState({
    compass: false,
    star_color: false
  });

  const firstUpdate = useRef(true);
  const debouncedValue = useDebouncedValue(designDebounceInput, 2000);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    FetchLivePreview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, input, offsetHours]);
  
  const FetchLivePreview = async (offsetHour = offsetHours) => {
    if (!input.lat || !input.lon || !offsetHour) return;    
    const payload = JSON.parse(JSON.stringify(input));
    payload.text.heading = designDebounceInput.heading1;
    payload.text.heading_2 = designDebounceInput.heading2;
    payload.text.sub_heading = designDebounceInput.subHeading;
    payload.num_stars = designDebounceInput.num_stars;

    // Locking preview to only 2_3 aspect ratio
    delete payload.aspect_ratio;
    delete payload.res_scaler;
    payload.aspect_ratio = CREATE_MAP_DEFAULT_ASPECT_RATIO.ratio;
    payload.res_scaler = CREATE_MAP_DEFAULT_ASPECT_RATIO.scale;

    // Removing compass key and adding to string if true
    delete payload.compass;
    if (input.compass) {
      payload.bg_style = payload.bg_style + '_compass';
    }

    //Coordinates
    if (payload.text.coords.lat) {
      payload.text.coords = getCoordinatesText(payload.text.coords);
    }

    //TEMP
    payload.time = getGmtTime(input.time, offsetHour);
    try {
      setLoading(true);
      const response = await post(
        'cosmicframe_renderer',
        payload,
        IMAGE_API_URL
      );
      const imageBlob = await response.blob();
      setImage(URL.createObjectURL(imageBlob));
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div className='createmap-base'>
      <div className='createmap-tabContainer'>
        <h1 className='createmap-header'>
          <Link to={HOME_PATH}>
            <img src={PUBLIC_IMAGE_PATH + 'main_logo.png'} alt='logo' />
          </Link>
        </h1>

        <ul className='createmap-tabs'>
          <li
            className='createmap-tab'
            style={{
              borderBottom:
                mode === CREATE_MAP_TAB_MODES.moment &&
                `3px solid ${primaryColor}`
            }}
            onClick={() => setMode(CREATE_MAP_TAB_MODES.moment)}
          >
            Moment
          </li>
          <li
            className='createmap-tab'
            style={{
              borderBottom:
                mode === CREATE_MAP_TAB_MODES.design &&
                `3px solid ${primaryColor}`
            }}
            onClick={() => setMode(CREATE_MAP_TAB_MODES.design)}
          >
            Design
          </li>
          <li
            className='createmap-tab'
            style={{
              borderBottom:
                mode === CREATE_MAP_TAB_MODES.finalise &&
                `3px solid ${primaryColor}`
            }}
            onClick={() => setMode(CREATE_MAP_TAB_MODES.finalise)}
          >
            Finalise
          </li>
        </ul>

        {mode === CREATE_MAP_TAB_MODES.moment && (
          <CreateMapMoment
            tabChange={(value) => setMode(value)}
            FetchLivePreview={(value) => FetchLivePreview(value)}
          />
        )}

        {mode === CREATE_MAP_TAB_MODES.design && (
          <CreateMapDesign
            tabChange={(value) => setMode(value)}
            disabled={disable}
            updateDisabled={(value) => setDisable({ ...disable, ...value })}
          />
        )}

        {mode === CREATE_MAP_TAB_MODES.finalise && (
          <CreateMapFinalise
            ShowModal={props.ShowModal}
            ModalToggle={props.ModalToggle}
            tabChange={(value) => setMode(value)}
            resetImage={() => setImage('')}
          />
        )}
      </div>

      <div
        className='createmap-demoContainer'
        onContextMenu={(e) => e.preventDefault()}
      >
        {loading ? (
          <div className='createmap-mapLoaderContainer'>
            <RadarLoader size={350} dotSize={15} />
          </div>
        ) : image ? (
          <img src={image} alt='' />
        ) : (
          <img src={PUBLIC_IMAGE_PATH + 'sample.jpeg'} alt='' />
        )}
      </div>
    </div>
  );
}
