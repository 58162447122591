export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const PUBLIC_IMAGE_PATH = '/assets/images/';
export const ACCESS_TOKEN = 'user_access_token';
export const USER_LOGIN_EXPIRY_TIME = 60 * 60 * 24 * 10; // 10 Days

export const CREATE_MAP_TAB_MODES = {
  moment: 'moment',
  design: 'design',
  finalise: 'finalise'
};

export const CREATE_MAP_THEMES = [
  {
    value: 'moon',
    label: 'Moon',
    compass: [false],
    starColor: [true, false]
  },
  {
    value: 'carina_nebula',
    label: 'Carina Nebula',
    compass: [false, true],
    constellations: true,
    starColor: [false, true]
  },
  {
    value: 'charcoal',
    label: 'Charcoal',
    compass: [true],
    constellations: true,
    starColor: [false, true]
  },
  {
    value: 'blueprint',
    label: 'Blueprint',
    compass: [true],
    constellations: true,
    starColor: [false, true]
  },
  {
    value: 'plain_navy',
    label: 'Plain Navy',
    compass: [false, true],
    constellations: true,
    starColor: [false, true]
  },
  {
    value: 'aurora1',
    label: 'Aurora 1',
    compass: [false, true],
    constellations: true,
    starColor: [false, true]
  },
  {
    value: 'off_white',
    label: 'Off White',
    compass: [true],
    constellations: true,
    starColor: [false]
  },
  {
    value: 'parchment1',
    label: 'Parchment 1',
    compass: [true],
    constellations: true,
    starColor: [false]
  },
  {
    value: 'aurora2',
    label: 'Aurora 2',
    compass: [false, true],
    constellations: true,
    starColor: [false, true]
  }
];

export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
export const TIME_OBJ = {
  // hours: Array.from({ length: 12 }, (v, k) => k + 1),
  // minutes: Array.from({ length: 60 }, (v, k) => k),
  offset: range(-12, 12, 0.5),
  hours: range(1, 12, 1),
  minutes: range(0, 59, 1),
  meridium: ['AM', 'PM']
};
export const DATE_OBJ = {
  years: range(2021 - 50, 2021 + 50, 1),
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
  ]
};

export const getGmtTime = (timeInMs, offsetHour) => {
  const now = new Date(timeInMs);
  const utc_now = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );

  const dateTime = utc_now.setTime(
    utc_now.getTime() + offsetHour * 60 * 60 * 1000
  );

  return Math.floor(dateTime / 1000);
};
export const getCoordinatesText = (coordsObj) => {
  if (coordsObj.lat) {
    const latDeg = coordsObj.lat > 0 ? 'N' : 'S';
    const lonDeg = coordsObj.lon > 0 ? 'E' : 'W';

    const latDecimal = Math.abs(coordsObj.lat).toFixed(4);
    const lonDecimal = Math.abs(coordsObj.lon).toFixed(4);

    const coordsText = `${latDecimal} ${latDeg} ${lonDecimal} ${lonDeg}`;
    return coordsText;
  } else {
    throw new Error('Lat Lon object needed');
  }
};

export const getUserTimeOffset = () => {
  const d = new Date();
  const n = d.getTimezoneOffset();
  const timezone = n / -60;

  return timezone;
};

export const CREATE_MAP_ASPECT_RATIOS = [
  { ratio: '2_3', scale: 500 },
  { ratio: '4_5', scale: 250 }
];

// export const COUNTRIES = [
//   { label: "India", value: "india" },
//   { label: "Outside of India", value: "others" },
// ];
export const POSTER_FORMAT = [
  { label: 'Framed', value: 'hard_copy' },
  { label: 'Digital', value: 'soft_copy' }
];

export const POSTER_SIZES = ['8”x12"', '11”x14”'];

export const ORDER_STATUS = {
  pending: 'pending',
  paid: 'paid',
  processed: 'processed',
  shipped: 'shipped',
  delivered: 'delivered',
  failed: 'failed',
  cancelled: 'cancelled'
};

// DEFAULTS
export const CREATE_MAP_DEFAULT_THEME = 'carina_nebula';
export const CREATE_MAP_DEFAULT_NO_OF_STARS = 3000;
export const CREATE_MAP_DEFAULT_STAR_COLOR = true;
export const CREATE_MAP_DEFAULT_CONSTELLATIONS = true;
export const CREATE_MAP_DEFAULT_ASPECT_RATIO = { ratio: '2_3', scale: 500 };
export const CREATE_MAP_DEFAULT_SUBHEAD = 'THE STARS ABOVE';
export const CREATE_MAP_DEFAULT_HEADING_1 = 'THE STARS ABOVE YOU NOW';

export const INDIA = 'IN';
export const COUNTRIES = [];
export const OFFSET_CHART = {
  AF: +4.5,
  AL: +2.0,
  DZ: +2.0,
  AS: -11.0,
  AO: +1.0,
  AI: -4.0,
  AG: -4.0,
  AR: -3.0,
  AM: +4.0,
  AW: -4.0,
  AU: +10,
  AT: +1.0,
  AZ: +4.0,
  BS: -5.0,
  BH: +3.0,
  BD: +6.0,
  BB: -4.0,
  BY: +3.0,
  BE: +1.0,
  BZ: -6.0,
  BJ: +1.0,
  BM: -4.0,
  BT: +6.0,
  BO: -4.0,
  BA: +1.0,
  BW: +2.0,
  BR: -5.0,
  BG: +2.0,
  BF: +0.0,
  BI: +2.0,
  KH: +7.0,
  CM: +1.0,
  CA: -6.0,
  CV: -1.0,
  KY: -5.0,
  CF: +1.0,
  TD: +1.0,
  CL: -3.0,
  CN: +8.0,
  CX: +7.0,
  CC: +6.5,
  CO: -5.0,
  KM: +3.0,
  CD: +1.0,
  CK: -10,
  CR: -6.0,
  CI: +0.0,
  HR: +1.0,
  CY: +2.0,
  CZ: +1.0,
  DK: +1.0,
  DJ: +3.0,
  DM: -4.0,
  DO: -4.0,
  EC: -5.0,
  EG: +2.0,
  SV: -6.0,
  GQ: +1.0,
  ER: +3.0,
  EE: +2.0,
  ET: +3.0,
  FK: -3.0,
  FO: +0.0,
  FJ: +12,
  FI: +2.0,
  FR: +1.0,
  GF: -3.0,
  PF: -10,
  GA: +1.0,
  GM: +0.0,
  GE: +4.0,
  DE: +1.0,
  GH: +0.0,
  GI: +1.0,
  GR: +2.0,
  GL: -3.0,
  GD: -4.0,
  GP: -4.0,
  GU: +10.0,
  GT: -6.0,
  GG: +0.0,
  GN: +0.0,
  GW: +0.0,
  GY: -4.0,
  HT: -5.0,
  HM: +5.0,
  VA: +1.0,
  HN: -6.0,
  HK: +8.0,
  HU: +1.0,
  IS: +0.0,
  IN: +5.5,
  ID: +7.0,
  IR: +3.5,
  IQ: +3.0,
  IE: +0.0,
  IM: +0.0,
  IL: +2.0,
  IT: +1.0,
  JM: -5.0,
  JP: +9.0,
  JE: +0.0,
  JO: +2.0,
  KZ: +5.0,
  KE: +3.0,
  KI: +12,
  KP: +8.5,
  KR: +9.0,
  KW: +3.0,
  KG: +6.0,
  LA: +7.0,
  LV: +2.0,
  LB: +2.0,
  LS: +2.0,
  LR: +0.0,
  LI: +1.0,
  LT: +2.0,
  LU: +1.0,
  MK: +1.0,
  MG: +3.0,
  MW: +2.0,
  MY: +8.0,
  MV: +5.0,
  ML: +0.0,
  MT: +0.0,
  MH: +12,
  MQ: -4.0,
  MR: +0.0,
  MU: +4.0,
  YT: +3.0,
  MX: -6.0,
  FM: +10,
  MD: +2.0,
  MC: +1.0,
  MN: +8.0,
  MS: -4.0,
  MA: +0.0,
  MZ: +2.0,
  MM: +6.5,
  NA: +1.0,
  NR: +12,
  NP: +5.5,
  NL: +1.0,
  AN: -4.0,
  NZ: +12,
  NI: -6.0,
  NE: +1.0,
  NG: +1.0,
  NU: -11,
  NF: +11.5,
  MP: +10,
  NO: +1.0,
  OM: +4.0,
  PK: +5.0,
  PW: +9.0,
  PS: +2.0,
  PA: -5.0,
  PG: +10,
  PY: -4.0,
  PE: -5.0,
  PH: +8.0,
  PL: +1.0,
  PT: +0.0,
  PR: -4.0,
  QA: +3.0,
  RE: +4.0,
  RU: +0.0,
  RW: +2.0,
  SH: +0.0,
  KN: -4.0,
  LC: -4.0,
  PM: -3.0,
  VC: -4.0,
  WS: +13,
  SM: +1.0,
  ST: +0.0,
  SA: +3.0,
  SN: +0.0,
  SC: +4.0,
  SL: +0.0,
  SG: +8.0,
  SK: +1.0,
  SI: +1.0,
  SB: +11,
  SO: +3.0,
  ZA: +2.0,
  GS: -2.0,
  ES: +1.0,
  LK: +5.5,
  SD: +3.0,
  SR: -3.0,
  SJ: +1.0,
  SZ: +2.0,
  SE: +1.0,
  CH: +1.0,
  SY: +2.0,
  TW: +8.0,
  TJ: +5.0,
  TZ: +3.0,
  TH: +7.0,
  TG: +0.0,
  TK: +13,
  TO: +13,
  TT: +13,
  TN: +1.0,
  TR: +2.0,
  TM: +5.0,
  TV: +12,
  UG: +3.0,
  UA: +2.0,
  AE: +4.0,
  GB: +0.0,
  US: -6.0,
  UY: -3.0,
  UZ: +5.0,
  VU: +11,
  VE: -4.5,
  VN: +7.0,
  VG: -4.0,
  VI: -4.0,
  WF: +12,
  EH: +1.0,
  YE: +3.0,
  ZM: +1.0,
  ZW: +2.0,
  AX: +2.0,
  AD: +1.0,
  AQ: +13,
  BV: +1.0,
  IO: +6.0,
  BN: +8.0,
  CG: +1.0,
  CU: -5.0,
  TF: +5.0,
  XK: +1.0,
  LY: +2.0,
  MO: +8.0,
  NC: +11,
  PN: -8.0,
  RO: +2.0,
  RS: +1.0,
  ME: +1.0,
  TL: +9.0,
  TC: -5.0,
  UM: -11.0
};

export const CREATE_ORDER_PATH = '/create-order';
export const HOME_PATH = '/';
export const FAQ_PATH = '/faq';
export const ABOUT_US_PATH = '/about-us';
export const CONTACT_US_PATH = '/contact-us';
export const PRIVACY_POLICY_PATH = '/privacy-policy';
export const SHIPPING_RETURN_POLICY_PATH = '/shipping-return-policy';
export const TERMS_CONDITION_PATH = '/terms-condition';
