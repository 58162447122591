import React from "react";
import {
  PUBLIC_IMAGE_PATH,
  CREATE_ORDER_PATH,
  HOME_PATH
} from "../../../utils/Constants";
import "./Css/Header.css";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="homeHeader-base">
      <div class="homeHeader-logo">
        <Link to={HOME_PATH}>
          <img src={PUBLIC_IMAGE_PATH + "main_logo.png"} alt="logo" />
        </Link>
      </div>
      <div className="homeHeader-list">
        {/* <div className="homeHeader-listItem">
          <a href="#aboutUs">About Us</a>
        </div> */}
        <div className="homeHeader-button">
          <Link to={CREATE_ORDER_PATH}>
            <button
              className="homeHeader-buttonElement"
              style={{ borderRadius: 5 }}
            >
              Order Now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
