export default function Disable(props) {
  return (
    <div
      style={{
        opacity: props.disabled ? 0.3 : 1,
        cursor: props.disabled && "not-allowed",
        // pointerEvents: props.disabled && "none",
      }}
      onClick={(e) => {
        if (props.disabled) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }}
    >
      {props.children}
    </div>
  );
}

Disable.defaultProps = {
  disabled: false,
};
