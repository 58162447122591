import React, { useContext } from 'react';
import './Css/CreateMap.css';
import Button from '../../../general/Button';
import {
  CREATE_MAP_ASPECT_RATIOS,
  CREATE_MAP_TAB_MODES,
  CREATE_MAP_THEMES,
  PUBLIC_IMAGE_PATH,
  TIME_OBJ
} from '../../../utils/Constants';
import {
  hoverBackgroundColor,
  primaryColor,
  primaryColorLight
} from '../../../utils/Theme';
import { get, post } from '../../../utils/apiRequest';
import Checkbox from '../../../general/Checkbox';
import Image from '../../../general/Image';
import RadarLoader from '../../../general/RadarLoader';
import CreateMapFinalise from './CreateMapFinalise';
import Accordion from '../../../general/Accordion';
import { useDebouncedValue } from '../../../utils/useDebounce';
import { UserJourneyContext } from '../ContextProvider/ContextProvider';
import SliderWithToolTip from '../../../general/SliderWithToolTip';
import Disable from '../../../general/Disable';
import Input from '../../../general/Input';

export default function CreateMapDesign(props) {
  const input = useContext(UserJourneyContext).designInput;
  const setInput = useContext(UserJourneyContext).setDesignInput;

  const designDebounceInput =
    useContext(UserJourneyContext).designDebounceInput;
  const setDesignDebounceInput =
    useContext(UserJourneyContext).setDesignDebounceInput;

  function changeTheme(value) {
    const options = {
      bg_style: value
    };

    // Resetting disabled to false
    props.updateDisabled({ compass: false, star_color: false });

    const selectedTheme = CREATE_MAP_THEMES.find((i) => i.value === value);

    if (selectedTheme) {
      if (selectedTheme.compass.length === 1) {
        options.compass = selectedTheme.compass[0];

        // Disabling compass
        props.updateDisabled({ compass: true });
      }
      if (selectedTheme.starColor.length === 1) {
        options.star_color = selectedTheme.starColor[0];

        // Disabling starColor
        props.updateDisabled({ star_color: true });
      }
      if (selectedTheme.starColor.length != 1) {
        options.star_color = true;
      }

      if (selectedTheme.value === 'parchment1') {
        options.star_color = false;
        options.compass = true;
        props.updateDisabled({ compass: true, star_color: true });
      }
      if (selectedTheme.value === 'off_white') {
        options.star_color = false;
        options.compass = true;
        props.updateDisabled({ compass: true, star_color: true });
      }

      if (selectedTheme.value === 'charcoal') {
        options.compass = true;
        props.updateDisabled({ compass: true ,star_color: false});
      }
      if (selectedTheme.value === 'blueprint') {
        options.compass = true;
        props.updateDisabled({ compass: true,star_color: false });
      }
    }

    input.bg_style !== value && setInput({ ...input, ...options });
  }

  function renderThemeOptions(themObj) {
    return (
      <Image
        src={PUBLIC_IMAGE_PATH + `themes/${themObj.value}.jpg`}
        paddingBottom={'138px'}
        onClick={() => changeTheme(themObj.value)}
      />     
    );
  }

  return (
    <div className='createmap-design-base'>
      <div className='createmap-inputContainer'>
        <label className='createmap-label'>Theme Options</label>

        <div className='createmap-theme-option'>
          {/* <p>Select Theme</p> */}

          <div className='createmap-imageThumbnailContainer'>
            {CREATE_MAP_THEMES.map((themeOption, index) => {
              return (
                <div
                  key={index}
                  className='createmap-themeOptions'
                  style={{
                    borderColor:
                      input.bg_style === themeOption.value && primaryColor
                  }}
                >
                  {renderThemeOptions(themeOption)}
                </div>
              );
            })}
          </div>
        </div>

        <Disable disabled={props.disabled.compass}>
          <div className='createmap-theme-option'>
            <p>Compass</p>
            <Checkbox
              disabled={props.disabled.compass}
              checked={input.compass}
              changeSelected={(value) =>
                setInput({
                  ...input,
                  compass: value
                })
              }
              backgroundColor={primaryColor}
            />
          </div>
        </Disable>

        <div className='createmap-theme-option'>
          <p>Constellations</p>
          <Checkbox
            checked={input.constellations}
            changeSelected={(value) =>
              setInput({ ...input, constellations: value })
            }
            backgroundColor={primaryColor}
          />
        </div>

        <Disable disabled={props.disabled.star_color}>
          <div className='createmap-theme-option'>
            <p>Star Color</p>
            <Checkbox
              disabled={props.disabled.star_color}
              checked={input.star_color}
              changeSelected={(value) =>
                setInput({ ...input, star_color: value })
              }
              backgroundColor={primaryColor}
            />
          </div>
        </Disable>

        <div className='createmap-theme-option'>
          <p>
            Number Of Stars: <small>{designDebounceInput.num_stars}</small>
          </p>
          <div className='createmap-slider'>
            <SliderWithToolTip
              value={designDebounceInput.num_stars}
              min={500}
              max={4000}
              onChange={(value) =>
                setDesignDebounceInput({
                  ...designDebounceInput,
                  num_stars: parseInt(value)
                })
              }
              showToolTip={true}
              height={12}
              activeColor={primaryColor}
            />
          </div>
        </div>
      </div>

      {/* <div className="createmap-inputContainer">
        <label className="createmap-label">Aspect Ratio</label>

        <div className="createmap-aspectRatioFrameContainer">
          {CREATE_MAP_ASPECT_RATIOS.map((i, index) => (
            <div
              key={index}
              className="createmap-aspectRatioFrame"
              style={{
                width: i.ratio.split("_")[0] * 45,
                height: i.ratio.split("_")[1] * 45,
                borderColor: input.aspect_ratio === i.ratio && primaryColor,
              }}
              onClick={() =>
                setInput({
                  ...input,
                  aspect_ratio: i.ratio,
                  res_scaler: i.scale,
                })
              }
            >
              {i.ratio.replace("_", ":")}
            </div>
          ))}
        </div>
      </div> */}

      <div className='createmap-accordion-base'>
        <Accordion
          title='Add Text'
          content={
            <>
              <div className='createmap-inputContainer'>
                <label className='createmap-label'>Heading Line 1</label>
                <input
                  className='createmap-input uppercase'
                  value={designDebounceInput.heading1}
                  onChange={(e) =>
                    setDesignDebounceInput({
                      ...designDebounceInput,
                      heading1: e.target.value.toUpperCase()
                    })
                  }
                />
              </div>

              <div className='createmap-inputContainer'>
                <label className='createmap-label'>Heading Line 2</label>
                <input
                  className='createmap-input uppercase'
                  value={designDebounceInput.heading2}
                  onChange={(e) =>
                    setDesignDebounceInput({
                      ...designDebounceInput,
                      heading2: e.target.value.toUpperCase()
                    })
                  }
                />
              </div>

              <div className='createmap-inputContainer'>
                <label className='createmap-label'>Sub Heading</label>
                <input
                  className='createmap-input uppercase'
                  value={designDebounceInput.subHeading}
                  onChange={(e) =>
                    setDesignDebounceInput({
                      ...designDebounceInput,
                      subHeading: e.target.value.toUpperCase()
                    })
                  }
                />
              </div>
            </>
          }
        />
      </div>

      <div className='createmap-accordion-base'>
        <Accordion
          title='Advanced Options'
          content={
            <>
              <div className='createmap-inputContainer'>
                <label className='createmap-label'>Edit Time Text</label>

                <input
                  className='createmap-input uppercase'
                  placeholder='01 JAN 2022 12:00 PM'
                  value={input.text.time}
                  onChange={(e) =>
                    setInput({
                      ...input,
                      text: {
                        ...input.text,
                        time: e.target.value.toUpperCase()
                      }
                    })
                  }
                />
              </div>

              <div className='createmap-inputContainer'>
                <label className='createmap-label'>Edit Location Text</label>

                <input
                  className='createmap-input uppercase'
                  placeholder='Location'
                  value={input.text.location}
                  onChange={(e) =>
                    setInput({
                      ...input,
                      text: {
                        ...input.text,
                        location: e.target.value.toUpperCase()
                      }
                    })
                  }
                />
              </div>

              <div className='createmap-inputContainer'>
                <label className='createmap-label'>Edit Coordinates Text</label>

                <div className='createmap-inputContainer-splite'>
                  <div className='createmap-inputComponentContainer'>
                    <Input
                      fourDecimalOnly
                      placeholder='Latitude'
                      value={
                        input.text.coords.lat
                          ? Math.abs(input.text.coords.lat)
                          : ''
                      }
                      onChange={(value) =>
                        setInput({
                          ...input,
                          text: {
                            ...input.text,
                            coords: {
                              ...input.text.coords,
                              lat: parseFloat(value)
                            }
                          }
                        })
                      }
                      fontSize={14}
                      background={'#fff'}
                      hoverBackground={primaryColorLight}
                      borderColor={'#ccc'}
                      focusedBorderColor={primaryColor}
                      borderRadius={5}
                      padding='12px 10px'
                    />
                  </div>

                  <div className='select-wrapper'>
                    <select
                      className='createmap-selectBox'
                      defaultValue={input.text.coords.lat > 0 ? 1 : -1}
                      onChange={(e) => {
                        let latVal = -Math.abs(input.text.coords.lat);
                        if (parseInt(e.target.value) === 1)
                          latVal = Math.abs(input.text.coords.lat);

                        setInput({
                          ...input,
                          text: {
                            coords: {
                              ...input.text.coords,
                              lat: latVal
                            }
                          }
                        });
                      }}
                    >
                      <option value={1}>N</option>
                      <option value={-1}>S</option>
                    </select>
                  </div>

                  <div className='createmap-inputComponentContainer'>
                    <Input
                      fourDecimalOnly
                      placeholder='Longitude'
                      value={
                        input.text.coords.lon
                          ? Math.abs(input.text.coords.lon)
                          : ''
                      }
                      onChange={(value) =>
                        setInput({
                          ...input,
                          text: {
                            ...input.text,
                            coords: {
                              ...input.text.coords,
                              lon: parseFloat(value)
                            }
                          }
                        })
                      }
                      fontSize={14}
                      background={'#fff'}
                      hoverBackground={primaryColorLight}
                      borderColor={'#ccc'}
                      focusedBorderColor={primaryColor}
                      borderRadius={5}
                      padding='12px 10px'
                    />
                  </div>

                  <div className='select-wrapper'>
                    <select
                      className='createmap-selectBox'
                      defaultValue={input.text.coords.lon > 0 ? 1 : -1}
                      onChange={(e) => {
                        let lonValue = -Math.abs(input.text.coords.lon);
                        if (parseInt(e.target.value) === 1)
                          lonValue = Math.abs(input.text.coords.lon);
                        setInput({
                          ...input,
                          text: {
                            coords: {
                              ...input.text.coords,
                              lon: lonValue
                            }
                          }
                        });
                      }}
                    >
                      <option value={1}>E</option>
                      <option value={-1}>W</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
      <div className='createmap-btnContainer'>
        <Button
          label='Next'
          width={'100%'}
          backgroundColor={primaryColor}
          hoverBackgroundColor={hoverBackgroundColor}
          textStyle={{ fontSize: 16, fontWeight: 600 }}
          borderRadius={7}
          onClick={() => props.tabChange(CREATE_MAP_TAB_MODES.finalise)}
        />
      </div>
    </div>
  );
}
