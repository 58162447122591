import { useState, useContext } from 'react';
import Button from '../../../general/Button';
import Modal2 from '../../../general/Modal';
import Modal from '../../../general/Modal';
import {
  CREATE_MAP_TAB_MODES,
  HOME_PATH,
  INDIA,
  POSTER_FORMAT
} from '../../../utils/Constants';
import { hoverBackgroundColor, primaryColor } from '../../../utils/Theme';
import { UserJourneyContext } from '../ContextProvider/ContextProvider';
import CreateMapCart from './CreateMapCart';
import { Link } from 'react-router-dom';

export default function CreateMapFinalise(props) {
  const resetAll = useContext(UserJourneyContext).resetAll;
  const selectedCountry = useContext(UserJourneyContext).selectedCountry;
  const costDetails = useContext(UserJourneyContext).costDetails;
  const purchaseDetails = useContext(UserJourneyContext).purchaseDetails;
  const setPurchaseDetails = useContext(UserJourneyContext).setPurchaseDetails;

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  let posterPriceDetails = costDetails.filter((i) => i.type === 'hard_copy');

  return (
    <div className='createmap-finalise-base'>
      <div className='createmap-inputContainer'>
        <label className='createmap-label'>Frame Options</label>

        <div className='createmap-theme-option'>
          <p>Frame Format</p>

          <div className='select-wrapper' style={{ maxWidth: 180 }}>
            <select
              className='createmap-theme-selectBox'
              defaultValue={purchaseDetails.orderType}
              onChange={(e) => {
                let subTotal = costDetails.find(
                  (i) => i.type === e.target.value
                ).price;
                let currency;
                let totalCost;
                let size = '';

                if (e.target.value === 'soft_copy') size = '';

                if (e.target.value === 'hard_copy' && !purchaseDetails.size)
                  size = posterPriceDetails[1].frameSize;

                if (selectedCountry === INDIA) {
                  subTotal = subTotal.inr;
                  totalCost = subTotal - purchaseDetails.discount;
                  currency = 'INR';
                } else {
                  subTotal = subTotal.usd;
                  totalCost = subTotal - purchaseDetails.discount;
                  currency = 'USD';
                }

                setPurchaseDetails({
                  ...purchaseDetails,
                  orderType: e.target.value,
                  size,
                  totalCost,
                  subTotal,
                  currency
                });
              }}
            >
              {POSTER_FORMAT.map((i, index) => {
                // Restricting Soft Copy in INDIA
                if (selectedCountry === INDIA && i.value == 'soft_copy') return;
                // Restricting Hard Copy outside INDIA
                if (selectedCountry !== INDIA && i.value == 'hard_copy') return;

                return (
                  <option key={index} value={i.value}>
                    {i.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {purchaseDetails.orderType === 'hard_copy' && (
          <div className='createmap-theme-option'>
            <p>Frame Size</p>

            <div className='select-wrapper' style={{ maxWidth: 180 }}>
              <select
                style={{ textTransform: 'none' }}
                className='createmap-theme-selectBox'
                defaultValue={purchaseDetails.size}
                onChange={(e) => {
                  let subTotal = posterPriceDetails.find(
                    (i) => i.frameSize === e.target.value
                  ).price;
                  let currency;
                  let totalCost;

                  if (selectedCountry === INDIA) {
                    subTotal = subTotal.inr;
                    totalCost = subTotal - purchaseDetails.discount;
                    currency = 'INR';
                  } else {
                    subTotal = subTotal.usd;
                    totalCost = subTotal - purchaseDetails.discount;
                    currency = 'USD';
                  }

                  setPurchaseDetails({
                    ...purchaseDetails,
                    size: e.target.value,
                    currency,
                    subTotal,
                    totalCost
                  });
                }}

                // onChange={(e) => {
                //   const data = JSON.parse(e.target.value);

                //   // const purchaseData = {
                //   //   subCost: data.price,
                //   // };

                //   // setPurchaseDetails(purchaseData);
                // }}
              >
                {/* {POSTER_SIZES.map((i, index) => (
                <option value={i.value} key={index}>
                  {i.label + " "}
                  {selectedCountry === INDIA ? "(₹" : "($"}

                  {i.value + ")"}
                </option>
              ))} */}

                {posterPriceDetails.map((i, index) => (
                  <option
                    value={i.frameSize}
                    // value={JSON.stringify({
                    //   orderType: "hard_copy",
                    //   size: i.size,
                    //   price:
                    //     selectedCountry === INDIA ? i.price.inr : i.price.usd,
                    // })}
                    key={index}
                  >
                    {i.frameSize}
                    {/* {selectedCountry === INDIA
                      ? `(₹${i.price.inr})`
                      : `($${i.price.usd})`} */}
                    {/* {selectedCountry  === INDIA
                    ? "(₹"
                    : "($"}

                  {i.price.inr + ")"} */}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>

      <div className='createmap-inputContainer'>
        <label className='createmap-label'>Purchase </label>

        {/* <div className="createmap-theme-option">
          <small>Instant Download Star Map (10 sizes)</small>
          <small>
            {selectedCountry === INDIA
              ? `(₹${purchaseDetails.subTotal})`
              : `($${purchaseDetails.subTotal})`}
          </small>
        </div> */}

        <div className='createmap-theme-option'>
          <small>Total Cost</small>
          <small>
            {selectedCountry === INDIA
              ? `(₹${purchaseDetails.subTotal})`
              : `($${purchaseDetails.subTotal})`}
          </small>
        </div>

        {purchaseDetails.discount ? (
          <div className='createmap-theme-option'>
            <small>Discount</small>
            <small>
              {selectedCountry === INDIA
                ? `(-₹${purchaseDetails.discount})`
                : `(-$${purchaseDetails.discount})`}
            </small>
          </div>
        ) : (
          ''
        )}

        <div className='createmap-theme-option'>
          <small>Final Cost</small>
          <small>
            {selectedCountry === INDIA
              ? `(₹${purchaseDetails.totalCost})`
              : `($${purchaseDetails.totalCost})`}
          </small>
        </div>

        {/* <div className="createmap-theme-option">
          <small>Subtotal</small>
          <small>
            {selectedCountry === INDIA
              ? `(₹${purchaseDetails.subTotal})`
              : `($${purchaseDetails.subTotal})`}
          </small>
        </div> */}
      </div>

      <div className='createmap-btnContainer'>
        <Button
          label='Add To Cart'
          // width={150}
          width={'100%'}
          backgroundColor={primaryColor}
          hoverBackgroundColor={hoverBackgroundColor}
          textStyle={{ fontSize: 16, fontWeight: 600 }}
          borderRadius={7}
          onClick={props.ModalToggle}
        />
      </div>

      <div className='createmap-addToCartContainer-desktop'>
        <Modal
          show={props.ShowModal}
          close={props.ModalToggle}
          position='right'
          style={{
            height: '100%',
            width: '45%'
          }}
          transform='translateX(200px)'
        >
          <CreateMapCart
            tabChange={() => props.tabChange(CREATE_MAP_TAB_MODES.moment)}
            showPaymentSuccessModal={() => setShowSuccessModal(true)}
          />
        </Modal>
      </div>

      <div className='createmap-addToCartContainer-mobile'>
        <Modal
          show={props.ShowModal}
          close={props.ModalToggle}
          position='bottom'
          style={{
            height: '60%',
            width: '100%'
          }}
          transform='translateY(200px)'
        >
          <CreateMapCart
            tabChange={() => props.tabChange(CREATE_MAP_TAB_MODES.moment)}
            showPaymentSuccessModal={() => setShowSuccessModal(true)}
          />
        </Modal>
      </div>

      <Modal2
        show={showSuccessModal}
        close={() => setShowSuccessModal(false)}
        // position="bottom"
        // position="right"
        // style={{
        //   height: "100vh",
        //   width: "100vw",
        // }}
        // transform="translateX(200px)"
      >
        <div
          style={{
            background: '#fff',
            padding: 80,
            borderRadius: 20,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <h1 style={{ marginBottom: 20 }}>Payment Successful</h1>
          <Link to={HOME_PATH}>
          <Button
            label='Continue'
            width={200}
            backgroundColor={primaryColor}
            hoverBackgroundColor={hoverBackgroundColor}
            textStyle={{ fontSize: 16, fontWeight: 600 }}
            borderRadius={7}
            onClick={() => {
              setShowSuccessModal(false);
              props.ModalToggle();
              // props.tabChange(HOME_PATH);
              props.resetImage();
              resetAll();
            }}
          />
          </Link>
        </div>
      </Modal2>
    </div>
  );
}
