import React from 'react';
import Button from '../../general/Button';
import { PUBLIC_IMAGE_PATH, CREATE_ORDER_PATH } from '../../utils/Constants';
import { primaryColorLight } from '../../utils/Theme';
import './Css/Home.css';
import { Link } from 'react-router-dom';
import { Footer } from './Footer/Footer';

function Home() {
  return (
    <div className='home-base' id='home'>
      {/* <Header /> */}
      <div className='home-hero-base'>
        <img
          class='home-hero-leftContainer-bg'
          src={PUBLIC_IMAGE_PATH + 'home-background.JPEG'}
          alt=''
        />
        <div className='home-hero-leftContainer'>
          <img
            src={PUBLIC_IMAGE_PATH + 'main_logo.png'}
            style={{
              borderRadius: 20
            }}
          />

          <h3>
            We take pride in creating beautiful star maps capturing the unique
            arrangement of stars in the sky on your special occasion. Create a
            star map for your first date, anniversary, birthdays, or any other
            occasion and give it as the perfect gift to your loved ones!
          </h3>
          <Link to={CREATE_ORDER_PATH}>
            <Button
              label={'Create Your Star Map'}
              borderRadius={5}
              textStyle={{ fontSize: 25, color: '#000', fontWeight: 700 }}
              height={50}
              backgroundColor={primaryColorLight}
            />
          </Link>
        </div>
        <div className='home-hero-rightContainer'>
          <img src={PUBLIC_IMAGE_PATH + 'image_top_text.jpg'} height={600} />
        </div>
      </div>

      <div className='aboutUs' id='aboutUs'>
        {/* <Icon image={'aboutUs.svg'} size={120} /> */}

        {/* <h1 className='aboutUs-title'>About us</h1> */}

        <div className='aboutUs-contentContainer'>
          <div className='aboutUs-leftContainer'>
            <img src={PUBLIC_IMAGE_PATH + 'image_1.jpg'} height={400} />
          </div>
          <div className='aboutUs-rightContainer'>
            <h2 className='aboutUs-contentTitle'>What is a star map?</h2>
            <p className='aboutUs-para'>
              A star map is a representation of the alignment of stars and the
              sky as you would have seen it on your special occasion. This star
              map is entirely unique to your occasion. As no two combinations of
              time and place on Earth have the same alignment of stars above
              them, no two star maps look alike.
            </p>
          </div>
          <div className='aboutUs-rightContainerMobileview'>
            <img src={PUBLIC_IMAGE_PATH + 'image_2.jpg'} height={400} />
          </div>


          <div className='aboutUs-leftContainer'>
            <h2 className='aboutUs-contentTitle'>How does it work?</h2>
            <p className='aboutUs-para'>
              We use proprietary algorithms in conjunction with a public catalog
              of the ten thousand brightest stars in the sky. This allows us to
              generate aesthetic and accurate star maps for any given time and
              place. We want you to see the magic happen before you order, which
              is why we offer a live preview of the star map for your occasion.
            </p>
          </div>
          <div className='aboutUs-rightContainer'>
            <img src={PUBLIC_IMAGE_PATH + 'image_2.jpg'} height={400} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Home;
