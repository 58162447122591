import { useContext, useState } from 'react';
import './Css/CreateMapCart.css';
import { UserJourneyContext } from '../ContextProvider/ContextProvider';
import Button from '../../../general/Button';
import {
  hoverBackgroundColor,
  primaryColor,
  primaryColorLight
} from '../../../utils/Theme';
import { post, patch } from '../../../utils/apiRequest';
import {
  ACCESS_TOKEN,
  getCoordinatesText,
  getGmtTime,
  PUBLIC_IMAGE_PATH,
  USER_LOGIN_EXPIRY_TIME,
  INDIA
} from '../../../utils/Constants';
import { createCookieInSeconds, getCookie } from '../../../utils/Cookies';
import Stripe from '../../../general/Stripe';
import RazorPay from '../../../general/RazorPay';
import Modal from '../../../general/Modal2';
import Input from '../../../general/Input';

export default function CreateMapCart(props) {
  const deliveryData = useContext(UserJourneyContext).deliveryDetails;
  const updateDeliveryData = useContext(UserJourneyContext).setDeliveryDetails;
  const designData = useContext(UserJourneyContext).designInput;
  const debounceDesignData = useContext(UserJourneyContext).designDebounceInput;
  const purchaseData = useContext(UserJourneyContext).purchaseDetails;
  const updatePurchaseData = useContext(UserJourneyContext).setPurchaseDetails;
  const selectedCountry = useContext(UserJourneyContext).selectedCountry;
  const razorPayData = useContext(UserJourneyContext).razorPayDetails;
  const updateRazorPayData = useContext(UserJourneyContext).setRazorPayDetails;
  const resetAll = useContext(UserJourneyContext).resetAll;
  const offsetHours = useContext(UserJourneyContext).offsetHours;

  const [showRazorPay, setRazorPay] = useState(false);
  const [couponApplied, setCouponApplied] = useState(
    purchaseData.discount > 0 ? true : null
  );
  const [orderId, setOrderId] = useState('');
  const [renderStrip, toggleStripModal] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  function getDiscountValue(couponInfo) {
    let discount = 0;
    if (couponInfo.type === 'percentage') {
      discount = (purchaseData.subTotal * couponInfo.value) / 100;
    } else {
      discount = couponInfo.value;
    }
    return discount;
  }

  const validateCoupon = async () => {
    if (!purchaseData.discountCode) {
      setCouponApplied(null);
      return;
    }
    const payload = {
      coupon: purchaseData.discountCode
    };

    try {
      const response = await post('coupon/validate', payload);
      const responseJson = await response.json();
      if (responseJson.status == 'failure') setCouponApplied(false);
      if (responseJson.status == 'success') {
        setCouponApplied(true);
        const discountAmt = getDiscountValue(responseJson.data.coupon);
        updatePurchaseData({
          ...purchaseData,
          totalCost: purchaseData.subTotal - discountAmt,
          discount: discountAmt
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function placeOrder() {
    setRazorPay(false);
    const designInfo = {
      time: designData.time,
      lat: designData.lat,
      lon: designData.lon,
      bg_style: designData.compass?designData.bg_style + "_compass":designData.bg_style,
      aspect_ratio: designData.aspect_ratio,
      res_scaler: designData.res_scaler,
      star_color: designData.star_color,
      constellations: designData.constellations,
      text: {
        location: designData.text.location,
        time: designData.text.time,
        coords: designData.text.coords,
        heading: debounceDesignData.heading1,
        heading_2: debounceDesignData.heading2,
        sub_heading: debounceDesignData.subHeading
      },
      num_stars: debounceDesignData.num_stars
    };

    // Temporary
    designInfo.time = getGmtTime(designInfo.time, offsetHours);
    //Coordinates
    if (designInfo.text.coords.lat) {
      designInfo.text.coords = getCoordinatesText(designInfo.text.coords);
    }

    const orderPayload = {
      deliveryDetails: deliveryData,
      designInfo,
      ...purchaseData
    };


    const orderResponse = await post('order', orderPayload);

    const orderResponseJson = await orderResponse.json();

    if (orderResponseJson.status == 'success') {
      // Setting orderId
      setOrderId(orderResponseJson.data._id);

      const razorPayload = {
        amount: orderResponseJson.data.totalAmount * 100,
        userId: orderResponseJson.data.userMeta.email
      };

      if (selectedCountry === INDIA) {
        await setRazorPay(true);
        const getRazorPayOrderId = await post(
          'razorPay/getOrderId',
          razorPayload
        );
        const getRazorPayOrderIdJson = await getRazorPayOrderId.json();
        if (getRazorPayOrderIdJson.status == 'success') {
          updateRazorPayData(getRazorPayOrderIdJson.data);
        }
      } else {
        openStripModal(true);
      }
      console.log(orderPayload);
    }

    try {
    } catch (err) {
      console.log(err);
    }
  }

  const registerCustomer = async () => {
    // If already registered place order
    const accessToken = getCookie(ACCESS_TOKEN);
    if (accessToken) {
      await placeOrder();
      return;
    }

    const registerPayload = {
      firstName: deliveryData.firstName,
      lastName: deliveryData.lastName,
      email: deliveryData.email,
      phoneNumber: deliveryData.phoneNumber
    };

    try {
      // First registering customer
      const registerResponse = await post('register', registerPayload);

      const registerResponseJson = await registerResponse.json();

      if (registerResponseJson.status === 'success') {
        // Creating cookie with access token for placing order
        createCookieInSeconds(
          ACCESS_TOKEN,
          registerResponseJson.data.token,
          USER_LOGIN_EXPIRY_TIME
        );

        // Ordering
        await placeOrder();
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function updateOrderStatus(razorPayObj) {
    const payload = {
      status: 'paid',
      transactionId: razorPayObj.razorpay_payment_id,
      razorpay_signature: razorPayObj.razorpay_signature,
      razorpay_order_id: razorPayObj.razorpay_order_id,
      orderId: orderId
    };

    const response = await patch(`order/updateOrderStatus/${orderId}`, payload);

    const responseJson = await response.json();

    // setPaymentSuccess(true);
    props.showPaymentSuccessModal();
  }

  const openStripModal = () => {
    toggleStripModal(true);
  };
  return (
    <>
      <Modal show={renderStrip} close={() => toggleStripModal(false)}>
        {renderStrip && <Stripe />}
      </Modal>
      {paymentSuccess ? (
        <div
          className='createMapCart-base'
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <h1 style={{ marginBottom: 20 }}>Payment Successful</h1>
          <Button
            label='Continue'
            width={200}
            backgroundColor={primaryColor}
            hoverBackgroundColor={hoverBackgroundColor}
            textStyle={{ fontSize: 16, fontWeight: 600 }}
            borderRadius={7}
            onClick={() => {
              resetAll();
              props.tabChange();
            }}
          />
        </div>
      ) : (
        <div className='createMapCart-base'>
          <div className='createmap-header'>
            <h1>Cart</h1>
          </div>

          <div className='createmap-inputContainer'>
            <label className='createmap-label'>Email</label>
            <input
              type='email'
              className='createmap-input'
              placeholder='Email Address'
              value={deliveryData.email}
              onChange={(e) =>
                updateDeliveryData({ ...deliveryData, email: e.target.value })
              }
            />
          </div>

          <div className='createmap-inputContainer-split'>
            <div className='createmap-inputContainer'>
              <label className='createmap-label'>First Name</label>
              <input
                className='createmap-input'
                placeholder='John'
                value={deliveryData.firstName}
                onChange={(e) =>
                  updateDeliveryData({
                    ...deliveryData,
                    firstName: e.target.value
                  })
                }
              />
            </div>

            <div className='createmap-inputContainer'>
              <label className='createmap-label'>Last Name</label>
              <input
                className='createmap-input'
                placeholder='Doe'
                value={deliveryData.lastName}
                onChange={(e) =>
                  updateDeliveryData({
                    ...deliveryData,
                    lastName: e.target.value
                  })
                }
              />
            </div>
          </div>
          {selectedCountry === INDIA && (
            <div>
              <div className='createmap-inputContainer'>
                <label className='createmap-label'>Address Line 1</label>
                <input
                  className='createmap-input'
                  placeholder='Enter your building number'
                  value={deliveryData.addressLine1}
                  onChange={(e) =>
                    updateDeliveryData({
                      ...deliveryData,
                      addressLine1: e.target.value
                    })
                  }
                />
              </div>

              <div className='createmap-inputContainer'>
                <label className='createmap-label'>Address Line 2</label>
                <input
                  className='createmap-input'
                  placeholder='Enter your building number'
                  value={deliveryData.addressLine2}
                  onChange={(e) =>
                    updateDeliveryData({
                      ...deliveryData,
                      addressLine2: e.target.value
                    })
                  }
                />
              </div>

              <div className='createmap-inputContainer-split'>
                <div className='createmap-inputContainer'>
                  <label className='createmap-label'>City</label>
                  <input
                    className='createmap-input'
                    placeholder='Enter your city'
                    value={deliveryData.city}
                    onChange={(e) =>
                      updateDeliveryData({
                        ...deliveryData,
                        city: e.target.value
                      })
                    }
                  />
                </div>
                <div className='createmap-inputContainer'>
                  <label className='createmap-label'>State</label>
                  <input
                    className='createmap-input'
                    placeholder='Enter your state'
                    value={deliveryData.state}
                    onChange={(e) =>
                      updateDeliveryData({
                        ...deliveryData,
                        state: e.target.value
                      })
                    }
                  />
                </div>
              </div>

              <div className='createmap-inputContainer-split'>
                <div className='createmap-inputContainer'>
                  <label className='createmap-label'>Pincode</label>
                  <Input
                    onlyNumber
                    maxLength={6}
                    placeholder='xxxxxx'
                    fontSize={14}
                    background={'#fff'}
                    hoverBackground={primaryColorLight}
                    borderColor={'#ccc'}
                    focusedBorderColor={primaryColor}
                    borderRadius={5}
                    padding='12px 10px'
                    value={deliveryData.zipCode}
                    onChange={(value) =>
                      updateDeliveryData({
                        ...deliveryData,
                        zipCode: value
                      })
                    }
                  />
                </div>

                <div className='createmap-inputContainer'>
                  <label className='createmap-label'>Phone</label>
                  <Input
                    onlyNumber
                    maxLength={10}
                    placeholder='xxxxxxxxxx'
                    fontSize={14}
                    background={'#fff'}
                    hoverBackground={primaryColorLight}
                    borderColor={'#ccc'}
                    focusedBorderColor={primaryColor}
                    borderRadius={5}
                    padding='12px 10px'
                    value={deliveryData.phoneNumber}
                    onChange={(value) =>
                      updateDeliveryData({
                        ...deliveryData,
                        phoneNumber: value
                      })
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <div className='createmap-inputContainer'>
            <label className='createmap-label'>Add Coupon</label>
            <div className='createmap-couponContainer'>
              <div className='createmap-inputRightChildContainer'>
                <input
                  className='createmap-input'
                  placeholder='Enter your coupon code to avail discount'
                  value={purchaseData.discountCode}
                  onChange={(e) => {
                    let totalCost = purchaseData.totalCost;
                    let discount = purchaseData.discount;
                    if (purchaseData.discountCode != e.target.value) {
                      totalCost = purchaseData.subTotal;
                      discount = 0;
                      setCouponApplied(null);
                    }

                    updatePurchaseData({
                      ...purchaseData,
                      totalCost,
                      discount,
                      discountCode: e.target.value.toUpperCase()
                    });
                  }}
                />

                {couponApplied !== null && (
                  <div
                    className='createmap-inputRightChild'
                    style={{
                      background: couponApplied && '#2fd52f',
                      color: couponApplied && '#fff'
                    }}
                  >
                    {couponApplied ? '✓' : '❌'}
                  </div>
                )}
              </div>

              <Button
                label={'Apply Code'}
                width={110}
                backgroundColor={primaryColorLight}
                border={`2px dotted ${primaryColor}`}
                borderRadius={15}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: primaryColor
                }}
                onClick={validateCoupon}
              />
            </div>
            {purchaseData.discount ? (
              <div>
                <div className='createmap-inputRightChild-appliedCouponinfo'>
                  <small>You saved additional: </small>
                  <small>
                    {selectedCountry === INDIA
                      ? `₹${purchaseData.discount} `
                      : `$${purchaseData.discount} `}
                  </small>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className='createmap-btnContainer'>
            <Button
              label={'Proceed to Pay'}
              width={200}
              backgroundColor={primaryColor}
              hoverBackgroundColor={hoverBackgroundColor}
              textStyle={{ fontSize: 16, fontWeight: 600 }}
              borderRadius={7}
              onClick={registerCustomer}
            />

            {showRazorPay && razorPayData.id && (
              <RazorPay
                order_id={razorPayData.id}
                amount={1000}
                currency={razorPayData.currency}
                logo={PUBLIC_IMAGE_PATH + 'submark_logo.png'}
                name={deliveryData.firstName + ' ' + deliveryData.lastName}
                phoneNumber={deliveryData.phoneNumber}
                email={deliveryData.email}
                onClick={async () => await registerCustomer()}
                onSuccess={(value) => updateOrderStatus(value)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
